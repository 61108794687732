<template>
  <div class='join'>
    <Title :chs="chs" :en="en" :flag="$route.path" :tel="tel" @submit="submit" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      chs: '招商加盟',
      en: 'Investment promotion and franchise',
      tel: '',
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$axios.get(this.$api.selectEnterpriseInfoDetail).then((res) => {
        this.tel = res.result.contactTel
      })
    },
    submit (e) {
      this.$axios.post(this.$api.insertCompanyApplyInfo, e).then((res) => {
        this.$message.success('提交成功')
        location.reload()
      }).catch((err) => {
        this.$message.error(err.desc)
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.join {
  height: 100%;
  background-color: #EEF4FF;
}
</style>
